// NotFound.tsx
import { Box, Text, VStack, Button, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { NavLink } from 'react-router-dom';

const UserPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      bgGradient="linear(to-b, white, gray.200)"
      className="contents-body"
      h="full"
      p={4}
    >
      <Text fontWeight={800} fontSize={24} mb={2}>
        {t('My Page')}
      </Text>
      <VStack spacing={6} textAlign="center" mt={20}>
        <Text fontSize="3xl" fontWeight="bold">
          {t('This page is currently under construction.')}
        </Text>
        <Text fontSize="md" maxW="sm">
          {t(
            'We’re working hard to bring you an exciting new experience. Stay tuned!'
          )}
        </Text>
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="gray.200"
          color="m-green.500"
          size="lg"
        />
        <NavLink to="/workspace/home">
          <Button
            borderWidth="2px"
            borderColor="neutral.900"
            variant="m-green"
            rounded="full"
          >
            {t('Return to Workspace')}
          </Button>
        </NavLink>
      </VStack>
    </Box>
  );
};

export default UserPage;
