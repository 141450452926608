/*
 * 파일명: TopNavigationBar.tsx
 *
 * 설명:
 * 상단 Navigation
 *
 * 특이사항:
 */
import {
  Button,
  Flex,
  Image,
  Tabs,
  Tab,
  TabList,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TopNavigationBar = () => {
  // 다국어 설정
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Tab 관리
  const tabItems = [
    {
      title: t('Workspace'),
      icon: 'flask-solid',
      url: '/workspace/home',
    },
    {
      title: t('My Page'),
      icon: 'user-solid',
      url: '/user',
    },
    {
      title: t('Manager'),
      icon: 'screwdriver-wrench-solid',
      url: '/management',
    },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index); // 탭 변경 시 상태 업데이트
    navigate(tabItems[index].url);
  };
  // useEffect(() => {
  //   navigate(tabItems[tabIndex].url);
  // }, [tabIndex]);

  // Navbar 감추기
  const [isFullPage, setIsFullPage] = useState(true);
  const fullPageRoute = ['/auth'];
  const checkFullPage = () => {
    const currentPath = location.pathname;
    setIsFullPage(
      currentPath === '/' ||
        fullPageRoute.some((path) => currentPath.startsWith(path))
    );
  };

  // 경로 변경시 풀페이지 여부 및 활성화 중인 메뉴 업데이트
  useEffect(() => {
    checkFullPage();
    const currentPath = location.pathname;
    const activeTabIdx = tabItems.findIndex((tab) =>
      currentPath.startsWith(`/${tab.url.split('/')[1]}`)
    );
    if (activeTabIdx !== tabIndex) {
      setTabIndex(activeTabIdx);
    }
  }, [location]);

  // 언어 설정 변경
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  // 테마 설정
  const { colorMode, toggleColorMode } = useColorMode();
  const [isDarkMode, setIsDarkMode] = useState(colorMode === 'dark');

  useEffect(() => {
    setIsDarkMode(colorMode === 'dark');
  }, [colorMode]);

  return (
    <Flex justifyContent="space-between" display={isFullPage ? 'none' : 'flex'}>
      <Tabs
        variant="enclosed-colored"
        colorScheme="neutral"
        flex={1}
        borderColor="neutral.900"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList className="bold-tabList">
          {tabItems.map((tab) => (
            <Tab key={tab.title}>
              <Flex alignItems="center" gap={1}>
                <Image
                  src={`/icons/${tab.icon}.svg`}
                  alt={tab.icon}
                  width="16px"
                  objectFit="contain"
                />
                <Text fontWeight={800}>{tab.title}</Text>
              </Flex>
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Flex
        borderColor="neutral.900"
        borderBottomWidth="2px"
        gap={3}
        alignItems="center"
      >
        <Image
          src="/sofia-logo.svg"
          alt="logo"
          height="20px"
          objectFit="contain"
        />
        <Text fontSize="13px" fontWeight={600} color="gray.500">
          v 0.0.1
        </Text>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            minWidth="24px"
            colorScheme="light-gray"
            color="neutral.900"
            rounded={0}
            height="42px"
            borderColor="neutral.900"
            borderWidth="2px"
            borderBottomWidth="0"
            _dark={{ bg: 'gray.800' }}
          >
            <Image
              src="/icons/gear-solid.svg"
              alt="gear"
              width="16px"
              objectFit="contain"
            />
          </MenuButton>
          <MenuList
            borderWidth={2}
            borderColor="neutral.900"
            bg="gray.200"
            color="neutral.900"
          >
            <MenuGroup title={t('Language')}>
              <MenuItem
                onClick={() => changeLanguage('ko')}
                bg="gray.50"
                color="neutral.900"
              >
                {t('korean')}
              </MenuItem>
              <MenuItem
                onClick={() => changeLanguage('en')}
                bg="gray.50"
                color="neutral.900"
              >
                {t('english')}
              </MenuItem>
            </MenuGroup>
            <MenuDivider borderBottomWidth="2px" />
            <MenuGroup title={t('Setting')}>
              <MenuItem bg="gray.50" color="neutral.900">
                {t('dark mode')}
                <Switch
                  ml={2}
                  isChecked={isDarkMode}
                  onChange={() => toggleColorMode()}
                  colorScheme="teal"
                ></Switch>
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <Button
          colorScheme="light-gray"
          color="neutral.900"
          rounded={0}
          height="42px"
          borderColor="neutral.900"
          borderWidth="2px"
          borderBottomWidth="0"
          fontWeight={800}
          onClick={() => navigate('/')}
          _dark={{ bg: 'gray.800' }}
        >
          Log Out
        </Button>
      </Flex>
    </Flex>
  );
};

export default TopNavigationBar;
