import { Box, Button, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const chartData = [
  { date: '2024-10-01', apiCalls: 2400 },
  { date: '2024-10-02', apiCalls: 1398 },
  { date: '2024-10-03', apiCalls: 9800 },
  { date: '2024-10-04', apiCalls: 3908 },
  { date: '2024-10-05', apiCalls: 4800 },
  { date: '2024-10-06', apiCalls: 3800 },
  { date: '2024-10-07', apiCalls: 4300 },
];

const ExamplePage3 = () => {
  return (
    <Box p={4}>
      <VStack alignItems="flex-start" spacing={4}>
        <Box mt={4}>대시보드 통합 조회 페이지</Box>
        <Link to="/management/dashboard/1">
          <Button width="sm" colorScheme="teal">
            상세로 이동
          </Button>
        </Link>
        <Box
          width="100%"
          height={300}
          p={4}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius={6}
          mb={4}
        >
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="apiCalls" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box
          width="100%"
          height={300}
          p={4}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius={6}
        >
          <ResponsiveContainer>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="apiCalls"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </VStack>
    </Box>
  );
};

export default ExamplePage3;
