import { Button, Flex, Textarea } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface TextareaInputBarProps {
  input: string;
  setInput: (value: string) => void;
  onClickInput: () => void;
  disabled: boolean;
}
const TextareaInputBar = ({
  input,
  setInput,
  onClickInput,
  disabled,
}: TextareaInputBarProps) => {
  // Textarea 요소 참조
  const textareaRef = useRef(null);
  // 다국어 설정어
  const { t } = useTranslation();

  // input keyboard 입력 감지
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      await onClickInput();
    }
  };

  // Textarea의 높이를 자동으로 조정하는 함수
  const adjustTextareaHeight = () => {
    const textarea: any = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // 높이를 초기화해서 자연스럽게 조정
      textarea.style.height = `${textarea.scrollHeight}px`; // 입력 내용에 따라 높이 설정
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]); // input이 변경될 때마다 호출

  return (
    <Flex gap={2} w="full">
      <Textarea
        ref={textareaRef}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown} // Shift + Enter 처리
        placeholder={t('Type a message')}
        resize="none" // 수동 크기 조정 불가
        height="fit-content"
        maxH="30vh" // 최대 높이를 화면의 3분의 1로 제한
        overflowY="auto" // 높이가 넘치면 스크롤
        rows={1} // 기본 높이 설정
        disabled={disabled}
        rounded={0}
        _focus={{ borderColor: 'gray.200', boxShadow: 'none' }}
      />
      <Button
        borderColor="neutral.900"
        borderWidth={2}
        fontWeight={700}
        rounded="full"
        variant="m-green"
        px={12}
        onClick={() => onClickInput()}
        disabled={disabled}
      >
        {t('Input')}
      </Button>
    </Flex>
  );
};

export default TextareaInputBar;
