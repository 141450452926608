/*
 * 파일명: chakaraFactory.ts
 *
 * 설명:
 * 전역적으로 사용할 HTML 태그 재정의
 *
 * 특이사항:
 */

import { chakra } from '@chakra-ui/react';
export const Div = chakra('div');
export const Span = chakra('span');
export const P = chakra('p');
