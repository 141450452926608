import React, { useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const AuthLoginPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: localStorage.getItem('m-ops-remember') || '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const toast = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (rememberMe) {
      localStorage.setItem('m-ops-remember', formData.username);
    } else {
      localStorage.removeItem('m-ops-remember');
    }

    // 로그인 로직 (이곳에 실제 인증 로직을 넣을 수 있습니다)
    if (formData.username === '' || formData.password === '') {
      toast({
        title: '로그인 실패',
        description: '아이디와 비밀번호를 입력해주세요.',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      toast({
        title: '로그인 성공',
        description: `${formData.username}님, 환영합니다!`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
      });
      navigate('/');
    }
  };

  return (
    <Box width="full" height="full" position="relative" className="animated-bg">
      <AbsoluteCenter>
        <Box
          bg="white"
          p={8}
          borderRadius="md"
          boxShadow="lg"
          maxW="400px"
          minW="350px"
          w="100%"
        >
          <Heading as="h2" size="lg" textAlign="center" mb={6}>
            로그인
          </Heading>
          을
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="username" isRequired>
                <FormLabel>아이디</FormLabel>
                <Input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  placeholder="아이디를 입력하세요"
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel>비밀번호</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="비밀번호를 입력하세요"
                />
              </FormControl>

              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                아이디 저장
              </Checkbox>

              <Button colorScheme="primary" size="lg" type="submit">
                로그인
              </Button>
            </Stack>
          </form>
          <Text textAlign="center" mt={4}>
            계정이 없으신가요? <Button variant="link">회원가입</Button>
          </Text>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
};

export default AuthLoginPage;
