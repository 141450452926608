/*
 * 파일명: api.tsx
 *
 * 설명:
 * 채팅 관련 api 모음
 *
 * 특이사항:
 */
import axios from '@/api/axios';

export const reqUploadReferences = async (form: any) => {
  const response = await axios.post('/upload-pdfs', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const reqSendMessage = async (form: any) => {
  try {
    // 첫 번째 요청 시도
    const response = await axios.post('/query', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error: any) {
    // 첫 번째 요청이 실패하고, 상태 코드가 500인 경우
    if (error.response && error.response.status === 500) {
      console.warn('500 에러 발생: 재시도 중...');
      // 재시도 요청
      try {
        const retryResponse = await axios.post('/query', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('재시도 성공 :::: ', retryResponse);
        return retryResponse;
      } catch (retryError) {
        console.error('재시도 실패 :::: ', retryError);
        throw retryError;
      }
    } else {
      // 상태 코드가 500이 아니거나 다른 에러 발생 시
      throw error;
    }
  }
};

export const reqGetChunk = async (id: string) => {
  const response = await axios.get(`/chunks/${id}`);
  return response;
};

export const reqGenerateQuestions = async (form: any) => {
  const response = await axios.post('/generate-questions', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};
