/*
 * 파일명: Loading.tsx
 *
 * 설명:
 * 페이지 내부 로딩 컴포넌트로 사용됨
 *
 * 특이사항:
 */
import { Box, Spinner, Text, VStack } from '@chakra-ui/react';

interface LoadingProps {
  message?: string;
}

const Loading = ({ message = 'Loading ...' }: LoadingProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="full"
      py={3}
    >
      <VStack spacing={2}>
        <Spinner
          thickness="4px"
          speed="0.8s"
          emptyColor="primary.200"
          color="primary.400"
          size="lg"
        />
        <Text fontSize="sm" fontWeight={500} color="primary.400">
          {message}
        </Text>
      </VStack>
    </Box>
  );
};

export default Loading;
