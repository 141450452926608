/*
 * 파일명: theme.ts
 *
 * 설명:
 * chakra-ui 의 테마 설정
 *
 * 특이사항:
 * https://v2.chakra-ui.com/docs/styled-system/customize-theme
 */

import { extendTheme, ThemeConfig } from '@chakra-ui/react';
// import type { StyleFunctionProps } from '@chakra-ui/styled-system';

// .env 파일에서 초기 테마 값 읽기
const themeMode = process.env.REACT_APP_THEME_MODE || 'light';

// 색상 모드 설정
const config: ThemeConfig = {
  initialColorMode: themeMode as 'light' | 'dark', // 기본 테마: 라이트 모드
  useSystemColorMode: false, // 시스템 설정 사용 여부
};

// 커스텀 테마
const theme = extendTheme({
  config,
  fonts: {
    body: 'NotoSansKR, Inter, system-ui, sans-serif',
    // heading: 'Georgia, serif',
    mono: 'Menlo, monospace',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50', // 라이트 모드 배경색
        color: 'black', // 라이트 모드 텍스트 색상
        _dark: {
          bg: 'gray.800', // 다크 모드 배경색
          color: 'white', // 다크 모드 텍스트 색상
        },
      },
      '.contents-body': {
        bg: 'white', // 라이트 모드 배경색
        color: 'black', // 라이트 모드 텍스트 색상
        _dark: {
          bg: 'gray.800', // 다크 모드 배경색
          color: 'white', // 다크 모드 텍스트 색상
        },
      },
    },
  },
  // 추가 커스텀 색상, 폰트 등
  colors: {
    primary: {
      50: '#E1B2F4',
      100: '#E9DBF2',
      200: '#D4B8E6',
      300: '#BF94DA',
      400: '#AA71CD',
      500: '#944DC1', // 기본?
      600: '#7F29B5',
      700: '#6A0D98',
      800: '#530080',
      900: '#401E62',
    },
    neutral: {
      50: '#F3F3F3',
      100: '#D3D3D4',
      200: '#B4B4B6',
      300: '#949497',
      400: '#757579',
      500: '#55555A',
      600: '#4A4B52',
      700: '#404049',
      800: '#353641',
      900: '#2A2C39',
    },
    'light-gray': {
      50: '#FCFEFF',
      100: '#F9FBFD',
      200: '#F4F7FB',
      300: '#E9EEF4',
      400: '#D5DEE9',
      500: '#D5DEE9',
      600: '#A8B6C3',
      700: '#8D9DAA',
      800: '#6F7E8B',
      900: '#5A6873',
    },
    'mid-gray': {
      50: '#F1F3F5',
      100: '#E2E6EA',
      200: '#CCD3DB',
      300: '#AEB8C2',
      400: '#8995A3',
      500: '#6C7A89',
      600: '#56606D',
      700: '#404854',
      800: '#2C323C',
      900: '#1E2228',
    },
    'm-blue': {
      50: '#e5e7ff',
      100: '#c7c9ff',
      200: '#a3a7ff',
      300: '#7e83ff',
      400: '#565cff',
      500: '#0018f9',
      600: '#0014d7',
      700: '#0011b5',
      800: '#000d91',
      900: '#00086b',
    },
    'm-green': {
      50: '#e3ffe1',
      100: '#b8ffb3',
      200: '#8dff85',
      300: '#62ff57',
      400: '#37ff29',
      500: '#0EFF00',
      600: '#0ccc00',
      700: '#09aa00',
      800: '#077700',
      900: '#045500',
    },
    error: 'red.500',
    success: 'green.500',
  },
  components: {
    Button: {
      baseStyle: {
        // 기본 스타일 설정
      },
      defaultProps: {
        colorScheme: 'mid-gray',
      },
      variants: {
        // 기본 gray 버튼에 대한 active 스타일 설정
        // solid: {
        //   // 기본 배경색 및 텍스트 색상
        //   bg: 'gray.200',
        //   color: 'gray.800',
        //   _active: {
        //     bg: 'gray.300', // active 상태의 배경색 지정
        //   },
        // },
        // solid: (props: StyleFunctionProps) => ({
        //   bg: props.colorScheme === 'gray' ? 'gray.200' : props.colorScheme,
        //   _hover: {
        //     bg: props.colorScheme === 'gray' ? 'gray.300' : props.colorScheme,
        //   },
        // }),
        'light-gray': {
          bg: 'gray.200',
          color: 'dark',
          _hover: {
            bg: 'gray.300', // active 상태의 배경색 지정
          },
          _active: {
            bg: 'gray.500', // active 상태의 배경색 지정
          },
        },
        'm-green': {
          bg: 'm-green.500',
          color: 'dark',
          _hover: {
            bg: 'm-green.600', // active 상태의 배경색 지정
          },
          _active: {
            bg: 'm-green.600', // active 상태의 배경색 지정
          },
        },
      },
    },
  },
});

export default theme;
