/*
 * 파일명: LoadingThreeDot.tsx
 *
 * 설명:
 * 채팅 화면에서 로딩 컴포넌트로 사용됨
 *
 * 특이사항:
 */
import { Box, Flex } from '@chakra-ui/react';
import '@/styles/components/_ui.scss';

function LoadingThreeDots() {
  return (
    <Flex
      align="center"
      justify="center"
      width="90px"
      height="38px"
      bg="gray.200"
      rounded="full"
    >
      <Box as="span" className="dot" />
      <Box as="span" className="dot" ml="2" />
      <Box as="span" className="dot" ml="2" />
    </Flex>
  );
}

export default LoadingThreeDots;
