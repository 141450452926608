import React, { useState } from 'react';
import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  RadioGroup,
  HStack,
  Radio,
  Select,
  Box,
} from '@chakra-ui/react';
import PageHeader from '@/components/ui/PageHeader';
import { useNavigate } from 'react-router-dom';

interface ProjectForm {
  projectName: string;
  projectCode: string;
  role: string;
  type: string;
}

const ExamplePage2 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ProjectForm>({
    projectName: '',
    projectCode: '',
    role: '',
    type: '',
  });

  const isError: boolean = formData.projectName?.length > 10;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (value: string) => {
    setFormData({ ...formData, role: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('등록 :::', formData);
    // 여기서 폼 데이터를 서버에 전송하거나 저장할 수 있습니다
  };

  const handleCancel = () => {
    navigate('/management/project'); // 취소 시 프로젝트 리스트로 이동
  };

  return (
    <VStack spacing={8} align="stretch" p={4}>
      <PageHeader
        title="프로젝트 등록"
        breadcrumbItems={[
          { title: '관리자', url: '/' },
          { title: '프로젝트 관리', url: '/management/project' },
          { title: '프로젝트 등록', url: '#' },
        ]}
      ></PageHeader>
      <Box borderWidth="1px" borderRadius="md" boxShadow="sm" p={5}>
        <form onSubmit={handleSubmit}>
          <FormControl id="projectName" mb={4} isRequired isInvalid={isError}>
            <FormLabel>프로젝트 명</FormLabel>
            <Input
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              placeholder="프로젝트 명을 입력하세요"
            />
            {!isError ? (
              <FormHelperText>10자까지 허용</FormHelperText>
            ) : (
              <FormErrorMessage>10자 이하로 작성해주세요.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="projectCode" mb={4} isRequired>
            <FormLabel>프로젝트 코드</FormLabel>
            <Input
              name="projectCode"
              value={formData.projectCode}
              onChange={handleChange}
              placeholder="프로젝트 코드를 입력하세요"
            />
          </FormControl>

          <FormControl id="role" mb={4} isRequired>
            <FormLabel>권한</FormLabel>
            <RadioGroup value={formData.role} onChange={handleRoleChange}>
              <HStack spacing={5}>
                <Radio value="admin">관리자</Radio>
                <Radio value="user">사용자</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>

          <FormControl id="type" mb={4} isRequired>
            <FormLabel>유형</FormLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              placeholder="유형을 선택하세요"
            >
              <option value="internal">내부 프로젝트</option>
              <option value="external">외부 프로젝트</option>
            </Select>
          </FormControl>

          <HStack justify="center" mt={6}>
            <Button colorScheme="primary" type="submit">
              등록
            </Button>
            <Button onClick={handleCancel}>취소</Button>
          </HStack>
        </form>
      </Box>
    </VStack>
  );
};

export default ExamplePage2;
