import { Outlet } from 'react-router-dom';
// import { useEffect } from 'react';
// import useCommonStore from '@/store/useCommonStore';

const WorkSpacePage = () => {
  // const { project } = useCommonStore(); // zustand에서 project를 불러옴
  // const navigate = useNavigate();
  //
  // useEffect(() => {
  //   if (!project?.id) {
  //     navigate('/management/dashboard'); // id가 없을 경우 리다이렉트
  //   }
  // }, [project, navigate]);

  return <Outlet></Outlet>;
};

export default WorkSpacePage;
