// NotFound.tsx
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, primary.400, primary.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {t('Page Not Found')}
      </Text>
      <Text color={'gray.500'} mb={6}>
        {t('The page you are looking for does not seem to exist.')}
      </Text>

      <Link to="/">
        <Button
          colorScheme="primary"
          bgGradient="linear(to-r, primary.400, primary.500, primary.600)"
          color="white"
        >
          {t('Go to Home')}
        </Button>
      </Link>
    </Box>
  );
};

export default NotFoundPage;
