import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || 'https://jsonplaceholder.typicode.com', // 기본 URL 설정
  timeout: 60000, // 선택사항: 타임아웃 설정
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터 설정 (선택사항)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // 토큰을 localStorage에서 가져오기
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 토큰이 있으면 Authorization 헤더에 추가
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 설정 (선택사항)
axiosInstance.interceptors.response.use(
  (response) => response, // 응답 성공 시
  (error) => {
    // 401 Unauthorized 같은 에러를 처리
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized, 로그인 페이지로 리다이렉트.');
    }
    if (error.response && error.response.status === 500) {
      console.log('Unauthorized, 로그인 페이지로 리다이렉트.');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
