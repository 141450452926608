/*
 * 파일명: EditableTitle.tsx
 *
 * 설명:
 * 퍈집가능 인풋
 *
 * 특이사항:
 */
import {
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EditableTitleProps {
  title: string;
  setTitle: (newTitle: string) => void;
}
const EditableTitle = ({ title, setTitle }: EditableTitleProps) => {
  // 다국어 설정어
  const { t } = useTranslation();
  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getEditButtonProps } =
      useEditableControls();

    return isEditing ? (
      <Button
        aria-label="완료"
        borderColor="neutral.900"
        borderWidth={2}
        colorScheme="light-gray"
        color="neutral.900"
        fontWeight={700}
        rounded="full"
        size="sm"
        {...getSubmitButtonProps()}
      >
        {t('Done')}
      </Button>
    ) : (
      <Button
        aria-label="수정"
        borderColor="neutral.900"
        borderWidth={2}
        colorScheme="light-gray"
        color="neutral.900"
        fontWeight={700}
        rounded="full"
        size="sm"
        {...getEditButtonProps()}
      >
        {t('Edit Chat Title')}
      </Button>
    );
  }

  return (
    <Editable
      value={title}
      onChange={(newTitle) => setTitle(newTitle)}
      onSubmit={(newTitle) => setTitle(newTitle)}
      isPreviewFocusable={false}
      display="flex"
      alignItems="center"
      gap={4}
    >
      <EditablePreview fontSize="2xl" fontWeight="bold" />
      <EditableInput fontSize="2xl" fontWeight="bold" />
      <EditableControls></EditableControls>
    </Editable>
  );
};

export default EditableTitle;
