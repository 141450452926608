/*
 * 파일명: SimpleModal.tsx
 *
 * 설명:
 * 기본 UI 의 모달 컴포넌트
 *
 * 특이사항:
 */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  body: ReactNode;
  footer?: ReactNode;
  size?: string; // 'sm', 'md', 'lg'
  contentClass?: string;
}

const SimpleModal = ({
  isOpen,
  onClose,
  title,
  body,
  footer,
  size = 'md',
  contentClass = '',
}: CustomModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent className={contentClass}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default SimpleModal;
