import React, { useEffect } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from './lib/theme';
import '@/styles/global.scss';
import '@/lib/i18n/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
// import SideNavigationBar from '@/components/layouts/SideNavigationBar';
import TopNavigationBar from '@/components/layouts/TopNavigationBar';
import AppRoutes from '@/routes/appRoutes';
import { Div } from '@/lib/chakraFactory';
import { Flex } from '@chakra-ui/react';

function App() {
  // 동적으로 뷰포트 높이를 계산 (모바일 브라우저 주소표시줄 이슈 방지)
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize(); // 초기 호출
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const appVersionFromLocal = localStorage.getItem('ops-app-version') || '';
  const appVersionFromEnv = process.env.REACT_APP_VERSION || '';
  const themeModeFromLocal = localStorage.getItem('chakra-ui-color-mode');
  const themeModeFromEnv = process.env.REACT_APP_THEME_MODE;
  // app version 을 이용하여 localStorage 테마 설정 덮어쓰기
  if (appVersionFromLocal !== appVersionFromEnv) {
    localStorage.setItem('ops-app-version', appVersionFromEnv);
    if (themeModeFromLocal !== themeModeFromEnv) {
      localStorage.setItem('chakra-ui-color-mode', themeModeFromEnv);
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Flex height="calc(var(--vh, 1vh) * 100)">
          {/*<SideNavigationBar></SideNavigationBar>*/}
          <Flex direction="column" flex="1" overflow="hidden">
            <TopNavigationBar></TopNavigationBar>
            <Div
              className="App"
              display={'flex'}
              flexDir={'column'}
              flex="1"
              overflowY="auto"
            >
              <AppRoutes></AppRoutes>
            </Div>
          </Flex>
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;
