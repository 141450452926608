import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const WorkspaceHomePage = () => {
  const navigate = useNavigate();
  const projects = [
    {
      id: 'type-c',
      title: '생성형 AI 프로젝트',
      children: [
        {
          id: 'type-c-p-1',
          title: '업무 효율화 챗봇',
          description:
            '사내 문서 검색, 고객 응대, 일정 관리등 다양한 비즈니스 상황에서의 업무를 더 빠르고 정확하게 처리하도록 도와줍니다.',
          tip: 'AI 에게 보여줄 PDF 문서를 준비해주세요.',
          imgUrl: '/images/robot.jpg',
          badgeTxt: '챗봇 UI 포함',
          linkUrl: '/workspace/chat/list',
        },
        {
          id: 'type-c-p-2',
          title: '맞춤형 이미지 생성',
          description:
            '제품 디자인, 마케팅, 콘텐츠 제작 등 다양한 목적에 맞춤형 이미지를 손쉽게 제공하여 창의적 작업의 효율성을 극대화합니다.',
          tip: '튜닝에 필요한 이미지 파일을 준비해주세요.',
          imgUrl: '/images/shoes.webp',
          badgeTxt: '프롬프트 포함',
          linkUrl: '',
        },
        {
          id: 'type-c-p-3',
          title: '자동화 API',
          description: '준비중입니다.',
          tip: '',
          imgUrl: '/images/animal.jpg',
          badgeTxt: 'Coming Soon',
          linkUrl: '',
        },
      ],
    },
    {
      id: 'type-l',
      title: '학습형 AI 프로젝트',
      children: [
        {
          id: 'type-l-p-1',
          title: '작성 전',
          description: '작성 전',
          tip: '작성전',
          imgUrl: '/images/robot.jpg',
          badgeTxt: '작성 전',
          linkUrl: '',
        },
      ],
    },
  ];

  const toast = useToast();
  const onNavigate = (url: string) => {
    if (url) {
      navigate(url);
    } else {
      toast({
        title: '프로젝트 이동',
        description: '준비 중인 기능입니다.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  return (
    <Box className="contents-body" p={4}>
      <Text fontWeight={800} fontSize={24} mb={2}>
        데모 프로젝트
      </Text>
      <VStack spacing={4}>
        {projects.map((project) => (
          <VStack key={project.id} alignItems="start" p={4} width="full">
            <Box height="27px" position="relative" mb={1}>
              <Badge
                width="20px"
                height="50%"
                position="absolute"
                left={0}
                bottom={0}
                bg="rgba(14, 255, 0, 0.9)"
                rounded="full"
              ></Badge>
              <Text
                fontWeight={600}
                fontSize={18}
                position="absolute"
                whiteSpace="nowrap"
              >
                {project.title}
              </Text>
            </Box>
            <Flex gap={4} flexWrap="wrap" justifyContent="start" width="full">
              {project.children.map((child) => (
                <Card
                  key={child.id}
                  direction={{ base: 'column', md: 'row' }}
                  variant="outline"
                  height={{ base: 'auto', md: '160px' }}
                  flex="1 1 calc(33.33% - 16px)"
                  maxWidth="calc(33.33% - 16px)"
                  minWidth="455px"
                  boxShadow="md"
                  borderWidth={2}
                  borderColor="neutral.900"
                  rounded={2}
                  cursor="pointer"
                  transition="transform 0.3s ease, box-shadow 0.3s ease"
                  _hover={{
                    transform: 'translateY(-6px)',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                  }}
                  onClick={() => onNavigate(child.linkUrl)}
                >
                  <Box
                    position="relative"
                    width={{ base: '100%', md: '160px' }}
                  >
                    <Image
                      objectFit="cover"
                      src={child.imgUrl}
                      width="100%"
                      height="100%"
                      alt="card image"
                    />
                    <Badge
                      position="absolute"
                      left={1}
                      top={1}
                      bg="m-green.500"
                      color="neutral.900"
                      rounded="full"
                      px={1.5}
                      borderWidth="2px"
                      borderColor="neutral.900"
                      fontWeight={800}
                    >
                      {child.badgeTxt}
                    </Badge>
                  </Box>

                  <Stack gap={0} flex={1}>
                    <CardBody pb={0} pt={3}>
                      <Heading size="md">{child.title}</Heading>
                      <Text
                        noOfLines={3}
                        isTruncated
                        whiteSpace="normal"
                        mt={2}
                        fontSize={14}
                        fontWeight={500}
                        color="neutral.500"
                      >
                        {child.description}
                      </Text>
                    </CardBody>

                    <CardFooter py={3}>
                      <Text fontSize={14} fontWeight={500} color="m-blue.500">
                        {child?.tip || ''}
                      </Text>
                    </CardFooter>
                  </Stack>
                </Card>
              ))}
            </Flex>
          </VStack>
        ))}
      </VStack>
    </Box>
  );
};

export default WorkspaceHomePage;
