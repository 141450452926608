import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
  Image,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import SimpleModal from '@/components/ui/SimpleModal';
import React, { useState } from 'react';

const MainPage = () => {
  const navigate = useNavigate();
  // 모달
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [formData, setFormData] = useState({
    email: localStorage.getItem('g-platform-account') || '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const toast = useToast();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (rememberMe) {
      localStorage.setItem('g-platform-account', formData.email);
    } else {
      localStorage.removeItem('g-platform-account');
    }

    // 로그인 로직 (이곳에 실제 인증 로직을 넣을 수 있습니다)
    const toastId = 'login-toast';
    if (formData.email === '' || formData.password === '') {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: '로그인 실패',
          description: '아이디와 비밀번호를 입력해주세요.',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    } else if (formData.email === 'aaa' && formData.password === '1234') {
      onClose();
      navigate('/workspace/home');
      toast({
        title: '로그인 성공',
        description: `${formData.email}님, 환영합니다!`,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: '로그인 실패',
          description: '아이디와 비밀번호를 확인해주세요.',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    }
  };

  return (
    <VStack
      className="main-container"
      width="full"
      height="full"
      justifyContent="space-between"
    >
      <VStack justifyContent="center" flex={1} spacing={9}>
        <Flex as="header" className="header">
          <Image
            src="/sofia-logo.svg"
            alt="SOFIA Logo"
            width="90vw"
            maxWidth={1200}
            objectFit="contain"
          />
          {/*<Heading as="h1" fontSize={200}>*/}
          {/*  SOFIA*/}
          {/*</Heading>*/}
        </Flex>
        <VStack spacing={5}>
          <Heading as="h2" size="xl" textAlign="center">
            기업의 AI를 쉽고 투명하게
          </Heading>
          <Text
            fontSize={18}
            fontWeight={500}
            maxWidth={420}
            align="center"
            color="neutral.600"
          >
            경영진, 세일즈팀, 전략기획팀 모두가 함께 보고 이해하는 ML/LLMOps
            구축은 소피아로 시작하세요.
          </Text>
          {/* 버튼 섹션 */}
          <VStack mt={4}>
            <NavLink to="https://whattime.co.kr/m-ultiply/sofia">
              <Button
                className="demo-button"
                size="lg"
                colorScheme="m-blue"
                rounded="full"
                borderColor="neutral.900"
                borderWidth={2}
              >
                🔥 데모 시연 요청 (1시간)
              </Button>
            </NavLink>
            <Text
              fontSize={14}
              fontWeight={500}
              align="center"
              color="neutral.400"
            >
              사업 목표 공유로부터 시작하는 깊은 수준의 데모 경험
            </Text>
            <Spacer />
            <Button
              size="lg"
              variant="m-green"
              rounded="full"
              borderColor="neutral.900"
              borderWidth={2}
              onClick={onOpen}
            >
              데모 환경 로그인
            </Button>
            <Flex>
              <Text
                fontSize={14}
                fontWeight={500}
                align="center"
                color="neutral.400"
              >
                계정이 없으신가요?
                <NavLink to="/">
                  <Button
                    colorScheme="blue"
                    variant="link"
                    size="sm"
                    ml={1}
                    color="dark"
                  >
                    데모 계정 발급 신청
                  </Button>
                </NavLink>
              </Text>
            </Flex>
          </VStack>
        </VStack>
      </VStack>

      {/* 하단 섹션 */}
      <Box as="footer" bg="m-blue.500" width="full" p={2.5}>
        <Text
          fontSize={13}
          fontWeight={600}
          color="white"
          align="center"
          letterSpacing={0.3}
        >
          엔터프라이즈 AI 운영 환경 구성을 위한 온프레미스 MLOps&LLMOps 구축
          패키지 | AI 전문 개발사 멀티플라이가 제공합니다.
        </Text>
      </Box>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        title="데모 환경 로그인"
        contentClass="bold-modal"
        body={
          <>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl id="email" isRequired>
                  <FormLabel>아이디</FormLabel>
                  <Input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="이메일 입력하세요"
                    borderColor="neutral.900"
                    borderWidth={2}
                    rounded={0}
                    _hover={{ borderColor: 'neutral.900' }}
                  />
                </FormControl>

                <FormControl id="password" isRequired>
                  <FormLabel>비밀번호</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="비밀번호를 입력하세요"
                    borderColor="neutral.900"
                    borderWidth={2}
                    rounded={0}
                    _hover={{ borderColor: 'neutral.900' }}
                  />
                </FormControl>

                <Checkbox
                  isChecked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="bold-checkbox"
                >
                  아이디 저장
                </Checkbox>

                <Button
                  type="submit"
                  width="full"
                  variant="m-green"
                  borderColor="neutral.900"
                  borderWidth={2}
                  size="lg"
                  my={4}
                >
                  로그인
                </Button>
              </Stack>
            </form>
          </>
        }
      />
    </VStack>
  );
};

export default MainPage;
