/*
 * 파일명: appRoutes.tsx
 *
 * 설명:
 * 경로 관리
 *
 * 특이사항:
 */
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFoundPage from '@/views/notFound';
import AuthLoginPage from '@/views/auth/login';
import MainPage from '@/views';
// 워크스페이스
import WorkSpacePage from '@/views/workspace';
import WorkspaceHomePage from '@/views/workspace/home';
import WorkspaceChatList from '@/views/workspace/chat/list';
import WorkspaceChatPage from '@/views/workspace/chat';
// 마이페이지
import UserPage from '@/views/user';
// 관리자
import ManagementPage from '@/views/management';
// 예시용
import ExamplePage from '@/views/example';
import ExamplePage2 from '@/views/example2';
import ExamplePage3 from '@/views/example3';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/auth/login" element={<AuthLoginPage />} />

      <Route path="/workspace" element={<WorkSpacePage />}>
        <Route path="home" element={<WorkspaceHomePage />} />
        <Route path="chat/list" element={<WorkspaceChatList />} />
        <Route path="chat/:id" element={<WorkspaceChatPage />} />
        {/* 기본 /workspace로 들어오면 /workspace/home으로 리다이렉트 */}
        <Route path="" element={<Navigate to="/workspace/home" />} />
      </Route>
      <Route path="/user" element={<UserPage />} />
      <Route path="/management" element={<ManagementPage />} />
      {/* 404 처리 */}
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/example" element={<ExamplePage />} />
      <Route path="/example2" element={<ExamplePage2 />} />
      <Route path="/example3" element={<ExamplePage3 />} />
    </Routes>
  );
};

export default AppRoutes;
