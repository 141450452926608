/*
 * 파일명: Pagination.tsx
 *
 * 설명:
 * 페이지네이션 컴포넌트
 *
 * 특이사항:
 * chakra ui 에서 제작한 페이지네이션은 유료여서 직접 제작함
 */
import { useState } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
interface PaginationProps {
  totalPages: number;
  currentPage: number;
  maxVisiblePages?: number;
  onPageChange: (page: number) => void;
  [others: string | number | symbol]: unknown;
}

const Pagination = ({
  totalPages,
  currentPage,
  maxVisiblePages = 10,
  onPageChange,
  ...others
}: PaginationProps) => {
  // 현재 페이지 그룹을 계산 (1~10, 11~20 등)
  const startPage =
    Math.floor((currentPage - 1) / maxVisiblePages) * maxVisiblePages + 1;
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const [activePage, setActivePage] = useState(currentPage);

  const handlePageChange = (page: number) => {
    setActivePage(page);
    onPageChange(page);
  };

  return (
    <HStack spacing={2} {...others}>
      <Button
        onClick={() => handlePageChange(1)}
        isDisabled={activePage === 1}
        variant="light-gray"
      >
        <ArrowLeftIcon></ArrowLeftIcon>
      </Button>
      <Button
        onClick={() => handlePageChange(activePage - 1)}
        isDisabled={activePage === 1}
        variant="light-gray"
      >
        Prev
      </Button>
      {pages.map((page) => (
        <Button
          key={`pagination-${page}`}
          onClick={() => handlePageChange(page)}
          bg={activePage === page ? 'primary.500' : 'gray.200'}
          color={activePage === page ? 'white' : 'dark'}
          _hover={{ bg: 'gray.300' }}
          className={activePage === page ? 'pointer-none' : ''}
        >
          {page}
        </Button>
      ))}

      <Button
        onClick={() => handlePageChange(activePage + 1)}
        isDisabled={activePage === totalPages}
        variant="light-gray"
      >
        Next
      </Button>
      <Button
        onClick={() => handlePageChange(totalPages)}
        isDisabled={activePage === totalPages}
        variant="light-gray"
      >
        <ArrowRightIcon></ArrowRightIcon>
      </Button>
    </HStack>
  );
};

export default Pagination;
