/*
 * 파일명: PageHeader.tsx
 *
 * 설명:
 * 페이지 상단 헤더 양식을 지정
 *
 * 특이사항:
 */
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
} from '@chakra-ui/react';
import { PageHeaderProps } from '@/types/components/ui/pageHeader';

const PageHeader = ({ title, breadcrumbItems }: PageHeaderProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text fontSize="2xl" fontWeight="bold">
        {title}
      </Text>
      <Breadcrumb fontSize="sm" spacing={0.5}>
        {breadcrumbItems.map((item, idx) => {
          return (
            <BreadcrumbItem
              key={`breadcrumb-${idx}`}
              isCurrentPage={idx === breadcrumbItems.length - 1}
              color={
                idx === breadcrumbItems.length - 1 ? 'gray.600' : 'gray.900'
              }
            >
              <BreadcrumbLink href={item.url}>{item.title}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Flex>
  );
};

export default PageHeader;
