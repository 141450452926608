import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import axiosInstance from '@/api/axios';
import SimpleModal from '@/components/ui/SimpleModal';
import Loading from '@/components/ui/Loading';
import DynamicFilter from '@/components/ui/DynamicFilter';
import PageHeader from '@/components/ui/PageHeader';
import Pagination from '@/components/ui/Pagination';
import SimpleTable from '@/components/ui/SimpleTable';
import { getPosts } from '@/features/management/client/api/api';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const ExamplePage = () => {
  const handleApiRequest = async () => {
    try {
      const response = await axiosInstance.get('/posts'); // JSONPlaceholder의 /posts 엔드포인트 호출
      console.log('res :: ', response.data);
    } catch (err) {
      console.log('error :: ', err);
    }
  };

  // 모달
  const { isOpen, onOpen, onClose } = useDisclosure();

  // toast
  const toast = useToast();
  const toastId = 'test-toast';
  const onToast = () => {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: '요청결과',
        description: '요청을 성공적으로 수행하였습니다.',
        status: 'success',
        variant: 'left-accent',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  //filter 와 테이블
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 3, // 한 페이지당 표시할 게시물 수
    totalRows: 100, // 전체 게시물 수
    totalPage: 20, // 전체 페이지 수
  });
  const [items, setItems] = useState<any[]>([]);

  // 필드 타입 선언
  interface FilterField {
    type: 'input' | 'select' | 'checkbox'; // 필드 유형
    name: string;
    label: string;
    options?: { label: string; value: string }[]; // select나 checkbox의 옵션들
    colSpan?: number; // 열에 차지할 공간, 기본값 1
  }
  const fields: FilterField[] = [
    { type: 'input', name: 'projectName', label: '프로젝트 명', colSpan: 2 }, // 한 열을 모두 차지
    { type: 'input', name: 'projectCode', label: '프로젝트 코드', colSpan: 1 },
    {
      type: 'select',
      name: 'projectType',
      label: '유형',
      colSpan: 1,
      options: [
        { label: '유형 1', value: 'type1' },
        { label: '유형 2', value: 'type2' },
      ],
    },
  ];
  const handleSearch = async (filters: Record<string, any>) => {
    console.log('검색 조건:', filters);
    await getPostsData();
  };
  const getPostsData = async () => {
    const response = await getPosts();
    setItems(response);
    setPagination((prev) => ({
      ...prev,
      totalRows: response.length,
    }));
  };

  const columns = [
    { field: 'name', label: '프로젝트 명' },
    { field: 'code', label: '프로젝트 코드' },
    { field: 'participants', label: '참여자 수' },
    {
      field: 'managers',
      label: '관리자 수',
      render: (rowData: any) => (rowData.managers >= 5 ? 'true' : 'false'),
    },
    { field: 'lastModified', label: '최근 수정 일시' },
    {
      field: 'action',
      label: 'Actions',
      render: (rowData: any) => (
        <HStack>
          <IconButton
            aria-label="Edit project"
            icon={<EditIcon />}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleTableAction('Edit', rowData.name);
            }}
          />
          <IconButton
            aria-label="Delete project"
            icon={<DeleteIcon />}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleTableAction('Delete', rowData.name);
            }}
          />
        </HStack>
      ),
    },
  ];

  const handleTableAction = (type: string, value: any) => {
    console.log(`table action : ${type} - ${value}`);
  };

  const handleSelectedRow = (row: any) => {
    console.log('selected row : ', row);
  };

  const rowData: any[] = [
    {
      name: '프로젝트1',
      code: '2asdf23klsadfjkl3j4dsfasdfa123',
      participants: 2,
      managers: 5,
      lastModified: '2024-02-12 08:40',
    },
    {
      name: '프로젝트2',
      code: 'dkjslfaj2110laskdjflasakjdhfkajh',
      participants: 5,
      managers: 2,
      lastModified: '2024-04-12 10:00',
    },
    {
      name: '프로젝트3',
      code: 'Tasdf23klsadfjkl3j4dsfasdfa123',
      participants: 2,
      managers: 1,
      lastModified: '2024-05-12 08:30',
    },
  ];
  const handlePageChange = async (page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
    }));
    await getPostsData();
  };

  useEffect(() => {
    console.log('items ::: ', items);
  }, [items]); // items가 변경될 때마다 호출됨

  return (
    <VStack alignItems="stretch" spacing={6} p={4}>
      <PageHeader
        title="예시 페이지"
        breadcrumbItems={[
          { title: 'Example', url: '#' },
          { title: 'Example#2', url: '#' },
        ]}
      ></PageHeader>
      <DynamicFilter
        fields={fields}
        onSearch={handleSearch}
        columns={3}
        mb={6}
      />
      <Flex justify="space-between" alignItems="center" w="100%" mb={2}>
        <Text>총 {pagination.totalRows}건</Text>
        <NavLink to="/example2">
          <Button colorScheme="primary">등록 예시</Button>
        </NavLink>
      </Flex>
      {/* Project Table */}
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mb={4}>
        <SimpleTable
          columns={columns}
          rowData={rowData}
          onSelectedRow={handleSelectedRow}
        ></SimpleTable>
      </Box>
      <Flex justifyContent="center">
        <Pagination
          totalPages={pagination.totalPage}
          currentPage={pagination.currentPage}
          onPageChange={handlePageChange}
        />
      </Flex>
      <Divider colorScheme="mid-gray" />
      <Flex gap={6}>
        <Button onClick={handleApiRequest}>API</Button>
        <Button onClick={onOpen}>Open 모달</Button>
        <Button onClick={onToast}>toast</Button>
      </Flex>
      <VStack
        border="1px"
        borderColor="gray.400"
        borderRadius={6}
        width="400px"
        p={2}
      >
        <Heading size="md" alignSelf="start">
          Pending
        </Heading>
        <Loading></Loading>
      </VStack>
      <SimpleModal
        isOpen={isOpen}
        onClose={onClose}
        title="모달 타이틀"
        body={<p>모달 내용입니다.</p>}
        footer={
          <>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              닫기
            </Button>
            <Button variant="ghost">확인</Button>
          </>
        }
      />
    </VStack>
  );
};

export default ExamplePage;
