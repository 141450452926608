/*
 * 파일명: DynamicFilter.tsx
 *
 * 설명:
 * 필터 컴포넌트를 공용으로 사용하여 필요한 기능들을 조건에 따라 유동적으로 사용 가능하도록함,
 *
 * 특이사항:
 */
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Checkbox,
  Select,
  Grid,
  GridItem,
} from '@chakra-ui/react';

interface FilterField {
  type: 'input' | 'select' | 'checkbox'; // 필드 유형
  name: string;
  label: string;
  options?: { label: string; value: string }[]; // select나 checkbox의 옵션들
  colSpan?: number; // 열에 차지할 공간, 기본값 1
}

interface DynamicFilterProps {
  fields: FilterField[];
  onSearch: (filters: Record<string, any>) => void;
  columns?: number; // Grid의 열 개수, 기본값 2
  [others: string | number | symbol]: unknown;
}

const DynamicFilter = ({
  fields,
  onSearch,
  columns = 2,
  ...others
}: DynamicFilterProps) => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(filters);
  };

  const handleReset = () => {
    setFilters({});
  };

  return (
    <Box p={5} borderWidth="1px" borderRadius="md" boxShadow="md" {...others}>
      <form onSubmit={handleSubmit}>
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4}>
          {fields.map((field) => (
            <GridItem key={field.name} colSpan={field.colSpan || 1}>
              <FormControl>
                <FormLabel>{field.label}</FormLabel>
                {field.type === 'input' && (
                  <Input
                    name={field.name}
                    value={filters[field.name] || ''}
                    onChange={handleChange}
                    placeholder={field.label}
                  />
                )}
                {field.type === 'select' && field.options && (
                  <Select
                    name={field.name}
                    value={filters[field.name] || ''}
                    onChange={handleChange}
                  >
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
                {field.type === 'checkbox' && (
                  <Checkbox
                    name={field.name}
                    isChecked={filters[field.name] || false}
                    onChange={handleChange}
                  >
                    {field.label}
                  </Checkbox>
                )}
              </FormControl>
            </GridItem>
          ))}
        </Grid>

        {/* 검색 및 초기화 버튼 */}
        <HStack spacing={2} justify="flex-end" mt={4}>
          <Button onClick={handleReset}>↻</Button>
          <Button colorScheme="primary" type="submit">
            검색
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default DynamicFilter;
