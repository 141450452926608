import {
  Avatar,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

function WorkspaceChatPage() {
  // 다국어 설정어
  const { t } = useTranslation();

  const chatHistory = [
    {
      title: '시스템 관리자는 어떤 기능을 수행할 수 있습니까?',
      references: [
        'doc20240102.pdf',
        'act20240602.pdf',
        'his20240402.pdf',
        'doc20240108.pdf',
        'doc20231202.pdf',
        'las20240102.pdf',
        'las20240108.pdf',
      ],
      updatedAt: '2024년 10월 11일',
    },
    {
      title: '기획 검토',
      references: [
        'doc20240102.pdf',
        'act20240602.pdf',
        'his20240402.pdf',
        'doc20240108.pdf',
        'doc20231202.pdf',
        'las20240102.pdf',
        'las20240108.pdf',
      ],
      updatedAt: '2024년 10월 09일',
    },
    {
      title: '레퍼런스 참고',
      references: [
        'doc20240102.pdf',
        'act20240602.pdf',
        'his20240402.pdf',
        'doc20240108.pdf',
        'doc20231202.pdf',
        'las20240102.pdf',
        'las20240108.pdf',
      ],
      updatedAt: '2024년 10월 08일',
    },
  ];

  const navigate = useNavigate();
  const onNavigate = () => {
    const randomNo = Math.floor(Math.random() * (5000 - 1) + 1);
    navigate(`/workspace/chat/${randomNo}`);
  };

  return (
    <VStack width="full" height="full" spacing={0} className="contents-body">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="full"
        p={4}
        borderBottomWidth="2px"
        borderColor="neutral.900"
      >
        <Wrap gap={0.25}>
          <WrapItem>
            <Avatar name="robot" src="/images/robot.jpg" />
          </WrapItem>
          <WrapItem height="48px" position="relative">
            <Badge
              width="20px"
              height="10px"
              position="absolute"
              left="-6px"
              top="10px"
              bg="rgba(14, 255, 0, 0.9)"
              rounded="full"
            ></Badge>
            <VStack
              alignItems="start"
              spacing={0}
              position="absolute"
              height="48px"
              width="200px"
            >
              <Text fontWeight={600} fontSize={12} color="neutral.700">
                생성형 AI 프로젝트
              </Text>
              <Text fontWeight={900} fontSize={24} lineHeight={1}>
                업무 효율화 챗봇
              </Text>
            </VStack>
          </WrapItem>
        </Wrap>
        <NavLink to={'/workspace/home'}>
          <Button
            borderColor="neutral.900"
            borderWidth={2}
            colorScheme="light-gray"
            color="neutral.900"
            fontWeight={700}
            rounded="full"
            size="sm"
          >
            {t('Return to Project List')}
          </Button>
        </NavLink>
      </Flex>
      <Flex className="flex-1-1-0" p={8} w="full" gap={4}>
        <Card
          w={240}
          h={250}
          borderWidth="2px"
          borderColor="neutral.900"
          borderRadius={2}
          cursor="pointer"
          onClick={() => onNavigate()}
          _hover={{ bg: 'green.100' }}
        >
          <CardBody>
            <VStack justifyContent="space-between" h="full" p={4}>
              <AddIcon boxSize="50px" mt={8}></AddIcon>
              <Text fontSize={20} fontWeight={700}>
                새로운 채팅
              </Text>
            </VStack>
          </CardBody>
        </Card>
        {chatHistory.length > 0 &&
          chatHistory.map((item, index) => (
            <Card
              key={`chat-${index}`}
              w={240}
              h={250}
              borderWidth="2px"
              borderColor="neutral.900"
              borderRadius={2}
              cursor="pointer"
              onClick={() => onNavigate()}
              role="group"
            >
              <CardHeader p={2} _groupHover={{ bg: 'green.100' }}>
                <Text
                  noOfLines={1}
                  isTruncated
                  whiteSpace="normal"
                  fontSize={16}
                  fontWeight={700}
                >
                  {item.title}
                </Text>
              </CardHeader>
              <CardBody
                borderColor="neutral.900"
                borderTopWidth="2px"
                borderBottomWidth="2px"
                className="flex-1-1-0"
                overflowY="auto"
                p={2}
              >
                <VStack w="full" alignItems="start">
                  {item.references.length > 0
                    ? item.references.map((ref, idx) => (
                        <Flex key={`ref${idx}`} gap={2} justifyContent="start">
                          <Badge
                            variant="outline"
                            bg="gray.300"
                            rounded="full"
                            px={2.5}
                            outlineOffset={2}
                          >
                            PDF
                          </Badge>
                          <Text
                            noOfLines={1}
                            isTruncated
                            whiteSpace="normal"
                            fontSize={14}
                            fontWeight={400}
                          >
                            {ref}
                          </Text>
                        </Flex>
                      ))
                    : null}
                </VStack>
              </CardBody>
              <CardFooter p={2}>
                <VStack alignItems="start" spacing={0}>
                  <Text fontSize={12} fontWeight={600} color="neutral.400">
                    {`총 ${item.references.length}개의 참조 데이터`}
                  </Text>
                  <Text fontSize={12} fontWeight={600} color="neutral.400">
                    {item.updatedAt}
                  </Text>
                </VStack>
              </CardFooter>
            </Card>
          ))}
      </Flex>
    </VStack>
  );
}

export default WorkspaceChatPage;
