/*
 * 파일명: SimpleTable.tsx
 *
 * 설명:
 * 기본 UI 테이블 컴포넌트
 *
 * 특이사항:
 */
import { Text, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';

interface ColumnsProps {
  field: string;
  label: string;
  render?: (rowData: any) => React.ReactNode;
}

interface TableProps {
  columns: ColumnsProps[];
  rowData: any[];
  onSelectedRow?: (row: any) => void;
  [others: string | number | symbol]: unknown;
}

const SimpleTable = ({
  columns,
  rowData,
  onSelectedRow,
  ...others
}: TableProps) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (row: any) => {
    setSelectedRow(row);
    if (onSelectedRow) {
      onSelectedRow(row); // 클릭된 row의 데이터를 전달
    }
  };
  return (
    <Table variant="simple" {...others}>
      <Thead bg="gray.100">
        <Tr>
          {columns.map((col) => (
            <Th key={`thead-${col.field}`}>{col.label}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rowData?.length > 0 ? (
          rowData.map((row, index) => (
            <Tr
              key={`tbody-tr-${index}`}
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              bg={selectedRow === row ? 'gray.200' : ''}
              onClick={() => handleRowClick(row)}
            >
              {columns.map((col, idx) => (
                <Td key={`tbody-td-${index}-${idx}`}>
                  {col.render
                    ? col.render(row)
                    : (row[col.field] as React.ReactNode)}
                </Td>
              ))}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={columns.length}>
              <Text textAlign="center">데이터가 존재하지 않습니다.</Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default SimpleTable;
