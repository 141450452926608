/*
 * 파일명: i18n.ts
 *
 * 설명:
 * 다국어 instance 설정
 *
 * 특이사항:
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ko from './locales/ko.json';

const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
};

// 시스템 언어를 적용할 경우
// const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  // lng: localStorage.getItem("language") || userLanguage || "en",
  lng: 'ko', // 기본언어
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

export const languages = ['en', 'ko'] as const;
export type Languages = (typeof languages)[number]; // 'en' | 'ko'
