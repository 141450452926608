/*
 * 파일명: AddReferenceModal.tsx
 *
 * 설명:
 * 채팅 참조 데이터를 추가용 모달
 *
 * 특이사항:
 */
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { reqUploadReferences } from '@/features/workspace/chat/api';
import LoadingThreeDots from '@/components/ui/LoadingThreeDot';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateReferences: (references: any[]) => void;
}

const AddReferenceModal = ({
  isOpen,
  onClose,
  onUpdateReferences,
}: CustomModalProps) => {
  // 다국어 설정어
  const { t } = useTranslation();
  // toast
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 직접 업로드
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      const formData = new FormData();
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append('files', acceptedFiles[i]);
      }
      try {
        const { status, data } = await reqUploadReferences(formData);
        if (status === 200) {
          // 받은 값을 부모로 전달
          onUpdateReferences(data.uuids_with_filenames);
        } else {
          toast({
            title: '참조 데이터 업로드',
            description: '업로드 중 문제가 발생하였습니다.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
          });
        }
      } catch (e) {
        console.log('error ::: ', e);
        toast({
          title: '참조 데이터 업로드',
          description: '업로드 중 문제가 발생하였습니다.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  // 웹사이트 URL
  const [input, setInput] = useState('');
  const getReferenceByUrl = () => {
    console.log('getReferenceByUrl :::', input);
    toast({
      title: '참조 데이터 업로드',
      description: '준비중인 기능입니다. 직접 업로드를 이용해주세요.',
      status: 'info',
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    });
  };

  // 웹사이트 URL
  const [textArea, setTextArea] = useState('');
  const getReferenceByText = () => {
    console.log('getReferenceByText :::', textArea);
    toast({
      title: '참조 데이터 업로드',
      description: '준비중인 기능입니다. 직접 업로드를 이용해주세요.',
      status: 'info',
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent className="bold-modal" width="full" maxWidth="80vw">
        <ModalHeader>참조데이터 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <VStack w="full" spacing={6}>
            <Text align="left" w="full" fontSize={14} fontWeight={600}>
              {t(
                'The response is generated based on the information deemed most important.'
              )}
              {t(
                '(ex: Marketing Plan, Lecture Materials, Research Notes, Meeting Minutes, Sales Records)'
              )}
            </Text>
            {isLoading ? (
              <VStack h="347px" justifyContent="center">
                <Text fontWeight={600} color="neutral.600">
                  Uploading data...
                </Text>
                <LoadingThreeDots></LoadingThreeDots>
              </VStack>
            ) : (
              <Grid
                width="full"
                templateAreas={`
                  "direct web"
                  "direct text"`}
                gridTemplateRows={'auto 1fr'}
                gridTemplateColumns={'30% 1fr'}
                gap={4}
              >
                <GridItem
                  area="direct"
                  borderWidth="2px"
                  borderColor="neutral.900"
                  p={4}
                  {...getRootProps()}
                >
                  <VStack w="full" h="full" justifyContent="center">
                    <input {...getInputProps()} />
                    <Text fontSize={24} fontWeight={900}>
                      {t('Upload data directly')}
                    </Text>
                    <Text fontSize={14} fontWeight={500}>
                      {t('Drag & drop files here, or')}
                    </Text>
                    <Button
                      aria-label="Upload file"
                      borderWidth="2px"
                      borderColor="neutral.900"
                      variant="m-green"
                      rounded="full"
                      size="sm"
                      px={6}
                      py={4}
                    >
                      {t('select file')}
                    </Button>
                  </VStack>
                </GridItem>
                <GridItem
                  area="web"
                  borderWidth="2px"
                  borderColor="neutral.900"
                  py={2}
                  px={3}
                >
                  <VStack w="full">
                    <Text w="full" align="left" fontSize={18} fontWeight={900}>
                      {t('Website')} URL
                    </Text>
                    <Flex w="full">
                      <InputGroup borderColor="neutral.900">
                        <Input
                          value={input}
                          placeholder={t('Paste the URL of the website')}
                          onChange={(e) => setInput(e.target.value)}
                          rounded="full"
                          borderWidth="2px"
                          className="input-border-neutral900"
                        />
                        <InputRightAddon
                          onClick={getReferenceByUrl}
                          bg="m-green.500"
                          borderWidth="2px"
                          className="input-border-neutral900"
                          cursor="pointer"
                          fontSize={14}
                          fontWeight={600}
                          marginInlineStart="-2px"
                          px={8}
                          rounded="full"
                          _hover={{ bg: 'm-green.600' }}
                        >
                          {t('Gathering')}
                        </InputRightAddon>
                      </InputGroup>
                    </Flex>
                    <Text w="full" align="left" fontSize={14} fontWeight={600}>
                      {t(
                        'Materials requiring separate login or payment are not recognized.'
                      )}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem
                  area="text"
                  borderWidth="2px"
                  borderColor="neutral.900"
                  py={2}
                  px={3}
                >
                  <VStack w="full">
                    <Text w="full" align="left" fontSize={18} fontWeight={900}>
                      {t('Enter Text Directly')}
                    </Text>
                    <Textarea
                      value={textArea}
                      borderRadius={12}
                      borderWidth="2px"
                      className="input-border-neutral900"
                      onChange={(e) => setTextArea(e.target.value)}
                      overflowY="auto" // 높이가 넘치면 스크롤
                      placeholder={t('Type or paste text')}
                      resize="none" // 수동 크기 조정 불가
                      rows={4} // 기본 높이 설정
                    />
                    <Button
                      borderWidth="2px"
                      borderColor="neutral.900"
                      variant="m-green"
                      rounded="full"
                      size="sm"
                      onClick={getReferenceByText}
                      px={8}
                      py={4}
                      ml="auto"
                    >
                      {t('Input')}
                    </Button>
                  </VStack>
                </GridItem>
              </Grid>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddReferenceModal;
