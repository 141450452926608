import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Highlight,
  Image,
  Fade,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { useTranslation } from 'react-i18next';
import {
  reqGenerateQuestions,
  reqGetChunk,
  reqSendMessage,
} from '@/features/workspace/chat/api';
import { NavLink } from 'react-router-dom';
import LoadingThreeDots from '@/components/ui/LoadingThreeDot';
import AddReferenceModal from '@/features/workspace/chat/components/AddReferenceModal';
import dayjs from 'dayjs';
import EditableTitle from '@/features/workspace/chat/components/EditableTitle';
import TextareaInputBar from '@/features/workspace/chat/components/TextareaInputBar';

// interface MessageBotType {
//   summaries?: any[];
//   follow_up_questions?: any[];
// }
interface Message {
  id: string;
  type: 'user' | 'bot'; // "user"는 우측, "bot"은 좌측에 표시됩니다.
  message: any;
  error?: boolean;
  errorMsg?: string;
  key?: any;
}
function WorkspaceChatPage() {
  // 다국어 설정어
  const { t } = useTranslation();
  //toast
  const toast = useToast();
  const toastId = 'chat-toast'; // 아이디를 지정하여 중복 생성방지

  // 모달
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    // 설정된 참조 파일 없으면 모달창 생성
    if (references.length < 1) {
      onOpen();
    }
  }, []);

  // 채팅 제목 임시로 생성
  const today = dayjs(new Date()).format('대화 YYYYMMDDHHMMss');
  const [title, setTitle] = useState(today);

  // 채팅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 등록된 문서 목록
  const [references, setReferences] = useState<any[]>([]);
  // 추천 질문
  const [followUpQuestions, setFollowUpQuestions] = useState<any[]>([]);
  // 채팅 내용
  const [messages, setMessages] = useState<Message[]>([]);
  // 전송 Input 의 메시지
  const [input, setInput] = useState('');
  // 가이드 데이터
  const [isGuideLoading, setIsGuideLoading] = useState<boolean>(false);
  const [guideData, setGuideData] = useState({
    summary: '',
    follow_up_questions: [],
  });
  // 참조 데이터(문서) 업데이트
  const updateReferences = async (addData: any[]) => {
    // 기존 참조데이터가 있었는지 여부를 통해 가이드 생성 여부 판별
    const isFirstAdd = references?.length === 0;
    await setReferences((prev) => [...prev, ...addData]);
    onClose();
    const ids = addData.length > 0 ? addData.map((item) => item.uuid) : [];
    // 참조 데이터 기본 모두 선택
    await setSelectedIds((prev) => [...prev, ...ids]);
    // 기존 참조데이터가 없었다면 가이드 생성
    if (isFirstAdd) {
      setIsGuideLoading(true);
      try {
        const formData = new FormData();
        addData.forEach((item) => {
          formData.append('uuid_list', item.uuid);
        });
        const { status, data } = await reqGenerateQuestions(formData);
        if (status === 200) {
          const questions = data.questions;
          setGuideData((prev) => ({
            ...prev,
            summary: questions.summary || '',
            follow_up_questions: questions.follow_up_questions || [],
          }));
        } else {
          setGuideData((prev) => ({
            ...prev,
            summary: '',
            follow_up_questions: [],
          }));
        }
      } catch (e) {
        console.log('reqGenerateQuestions error ::: ', e);
      } finally {
        setIsGuideLoading(false);
      }
    }
  };

  const onClickInput = async () => {
    const sendMsg = input.trim();
    if (sendMsg) {
      await handleSendMessage(sendMsg);
    }
  };

  // 메시지 전송 핸들러
  const handleSendMessage = async (sendMsg: string) => {
    setIsLoading(true);
    setMessages((prev) => [
      ...prev,
      {
        id: `user-${prev.length}`,
        type: 'user',
        message: sendMsg,
      },
    ]);
    const formData = new FormData();
    // 참조할 데이터들 id 목록
    selectedIds.forEach((id) => {
      formData.append('uuid_list', id);
    });
    formData.append('user_query', sendMsg);
    try {
      const { status, data } = await reqSendMessage(formData);
      if (status === 200) {
        // 문자열을 jsx 형태로 변환하여 message 에 추가하기
        const { response, follow_up_questions, key } = data;
        setMessages((prev) => [
          ...prev,
          {
            id: `bot-${prev.length}`,
            type: 'bot',
            message: (
              <Text whiteSpace="pre-wrap">{parseResponse(response, key)}</Text>
            ),
            key: key,
          },
        ]);
        setFollowUpQuestions(follow_up_questions || []);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            id: `bot-${prev.length}`,
            type: 'bot',
            message: '',
            error: true,
            errorMsg:
              '오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
          },
        ]);
      }
    } catch (error: any) {
      console.log('error ::: ', error);
      setMessages((prev) => [
        ...prev,
        {
          id: `bot-${prev.length}`,
          type: 'bot',
          message: '',
          error: true,
          errorMsg:
            '오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
    await setInput('');
  };

  // 채팅 메시지 변환
  const parseResponse = (text: any, key: any) => {
    const parts = text.split(/(\*\*.*?\*\*)/); // **로 감싸진 텍스트 분리
    return parts.map((part: any, index: any) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        const boldText = part.slice(2, -2); // ** 제거하여 텍스트 추출
        return key[boldText] ? (
          <strong
            key={index}
            onClick={() => handleClickKeyword(boldText, key)}
            style={{
              cursor: 'pointer',
              color: 'neutral.900',
              whiteSpace: 'pre-wrap',
            }}
          >
            {boldText}
          </strong>
        ) : (
          <span key={index}>{boldText}</span>
        );
      } else {
        return <span key={index}>{part}</span>; // 일반 텍스트는 그대로 표시
      }
    });
  };
  // // 클릭 시 key 배열 출력
  const handleClickKeyword = async (boldText: any, key: any) => {
    if (key[boldText]) {
      const uuid = key[boldText];
      // 해당 참조 데이터가 열려 있지 않은경우 오픈 요청
      if (refDetail.uuid !== uuid.split('_')[0]) {
        await getReferenceDetail(uuid.split('_')[0]);
      }
      await setHighlightId(uuid);
    } else {
      setHighlightId('');
      console.log(`${boldText}에 해당하는 키가 없습니다.`);
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: '참조 검색',
          description: '해당 부분의 참조 데이터를 찾을 수 없습니다.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    }
  };

  // 왼쪽 문서 목록 관련
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isAllChecked = selectedIds.length === references.length;
  const isIndeterminate = selectedIds.length > 0 && !isAllChecked;
  const [refDetail, setRefDetail] = useState<any>({
    uuid: '',
    file_name: '',
    chunks: [],
  });
  const [highlightId, setHighlightId] = useState<string>('');
  // 전체 선택/해제 핸들러
  const handleSelectAll = () => {
    setSelectedIds(isAllChecked ? [] : references.map((item) => item.uuid));
  };
  const handleCheckboxChange = (id: string) => {
    setSelectedIds(
      selectedIds.includes(id)
        ? selectedIds.filter((val) => val !== id)
        : [...selectedIds, id]
    );
    // setSelectedIds((prev) => ({
    //   ...prev,
    //   options: prev.includes(id)
    //     ? prev.filter((val) => val !== id) // 이미 선택된 옵션 제거
    //     : [...prev, id], // 선택되지 않은 옵션 추가
    // }));
  };

  const getReferenceDetail = async (reqId: string) => {
    try {
      const { status, data } = await reqGetChunk(reqId);
      if (status === 200) {
        setRefDetail({
          uuid: reqId,
          file_name: references.filter((item) => item.uuid === reqId)[0]
            .file_name,
          chunks: data?.chunks.length > 0 ? data.chunks : [],
        });
      } else {
        setRefDetail({
          uuid: '',
          file_name: '',
          chunks: [],
        });
      }
    } catch (e) {
      console.log('error ::: ', e);
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: '참조 데이터 조회',
          description: '데이터 조회 중 문제가 발생하였습니다.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    }
  };

  useEffect(() => {
    if (highlightId) {
      const element = document.getElementById(highlightId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [highlightId]);

  const bg1 = useColorModeValue('neutral.50', 'gray.900');
  const bg2 = useColorModeValue('white', 'gray.700');

  return (
    <VStack width="full" height="full" spacing={0} className="content-body">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="full"
        p={4}
        borderBottomWidth="2px"
        borderColor="neutral.900"
      >
        <Wrap gap={0.25}>
          <WrapItem>
            <Avatar name="robot" src="/images/robot.jpg" />
          </WrapItem>
          <WrapItem height="48px" position="relative">
            <Badge
              width="20px"
              height="10px"
              position="absolute"
              left="-6px"
              top="10px"
              bg="rgba(14, 255, 0, 0.9)"
              rounded="full"
            ></Badge>
            <VStack
              alignItems="start"
              spacing={0}
              position="absolute"
              height="48px"
              width="200px"
            >
              <Text fontWeight={600} fontSize={12} color="neutral.700">
                {t('Generative AI Project')}
              </Text>
              <Text
                fontWeight={900}
                fontSize={24}
                lineHeight={1}
                whiteSpace="nowrap"
              >
                {t('Productivity Enhancement Chatbot')}
              </Text>
            </VStack>
          </WrapItem>
        </Wrap>
        <NavLink to={'/workspace/home'}>
          <Button
            borderColor="neutral.900"
            borderWidth={2}
            colorScheme="light-gray"
            color="neutral.900"
            fontWeight={700}
            rounded="full"
            size="sm"
          >
            {t('Return to Project List')}
          </Button>
        </NavLink>
      </Flex>
      <Flex
        alignItems="center"
        borderBottomWidth="2px"
        borderColor="neutral.900"
        gap={4}
        w="full"
        px={4}
        py={2}
      >
        <EditableTitle title={title} setTitle={setTitle}></EditableTitle>
      </Flex>
      <Grid
        className="flex-1-1-0"
        gridTemplateColumns={refDetail.uuid ? '500px 1fr' : '220px 1fr'}
        overflowY="hidden"
        width="full"
      >
        {/* 레퍼런스 목록 */}
        <GridItem borderRightWidth="2px" borderRightColor="neutral.900">
          {refDetail.uuid ? (
            <VStack height="full" spacing={4} p={3}>
              <Flex
                width="full"
                height="40px"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="22px" fontWeight={600}>
                  {refDetail?.file_name || ''}
                </Text>
                <CloseButton
                  bg="gray.200"
                  borderColor="neutral.900"
                  borderWidth="2px"
                  onClick={() => {
                    setRefDetail({
                      uuid: '',
                      file_name: '',
                      chunks: [],
                    });
                  }}
                  rounded={0}
                  size="lg"
                  _hover={{ bg: 'gray.300' }}
                />
              </Flex>
              <VStack
                className="flex-1-1-0"
                overflowY="auto"
                whiteSpace="pre-wrap"
              >
                {refDetail.chunks.length > 0 &&
                  refDetail.chunks.map((chunk: any) => (
                    <Flex key={chunk.chunk_id}>
                      <Text id={chunk.chunk_id} whiteSpace="pre-wrap">
                        <Highlight
                          query={
                            highlightId === chunk.chunk_id
                              ? chunk.original_text
                              : ''
                          }
                          styles={{
                            bg: 'm-green.100',
                            whiteSpace: 'pre-wrap',
                            px: '2px',
                            rounded: 0,
                          }}
                        >
                          {chunk.original_text}
                        </Highlight>
                      </Text>
                    </Flex>
                  ))}
              </VStack>
            </VStack>
          ) : (
            <VStack height="full" spacing={4} p={3}>
              <Flex
                width="full"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontWeight={700}>{t('Reference Data')}</Text>
                <Button
                  borderColor="neutral.900"
                  borderWidth={2}
                  fontWeight={700}
                  rounded="full"
                  size="xs"
                  variant="m-green"
                  px={1.5}
                  onClick={() => onOpen()}
                >
                  {t('Add Data')}
                </Button>
              </Flex>
              {references.length > 0 && (
                <FormControl
                  id="references"
                  color="neutral.500"
                  className="flex-1-1-0"
                  display="flex"
                  flexDirection="column"
                >
                  <Checkbox
                    id="all-check"
                    isChecked={isAllChecked}
                    isIndeterminate={isIndeterminate}
                    mb={2}
                    onChange={handleSelectAll}
                    fontWeight={700}
                    colorScheme="m-blue"
                  >
                    <Text fontSize={14}> 모든 데이터 선택</Text>
                  </Checkbox>
                  <VStack
                    spacing={2}
                    alignItems="start"
                    className="flex-1-1-0"
                    overflowY="auto"
                  >
                    {references.map((item) => (
                      <Checkbox
                        id={item.uuid}
                        key={item.uuid}
                        isChecked={selectedIds.includes(item.uuid)}
                        onChange={() => handleCheckboxChange(item.uuid)}
                        fontWeight={500}
                        alignItems={'start'}
                        className="chakra-checkbox__control-mt-xs"
                        colorScheme="m-blue"
                      >
                        <Text fontSize={14}>
                          <Badge
                            variant="outline"
                            height="fit-content"
                            bg="gray.300"
                            rounded="full"
                            px={1.5}
                            outlineOffset={2}
                            mr={1}
                          >
                            PDF
                          </Badge>
                          <span
                            onMouseDown={async (e) => {
                              e.stopPropagation();
                              await getReferenceDetail(item.uuid);
                            }}
                          >
                            {item.file_name}
                          </span>
                        </Text>
                      </Checkbox>
                    ))}
                  </VStack>
                </FormControl>
              )}
            </VStack>
          )}
        </GridItem>
        <GridItem
          overflowX="hidden"
          p={4}
          bg={bg1}
          display="flex"
          flexDirection="column"
        >
          <Flex>
            {references.length > 0 ? (
              references
                .filter((ref) => selectedIds.includes(ref.uuid))
                .map((item) => (
                  <Flex
                    key={item.uuid}
                    bg={bg2}
                    borderTopWidth="2px"
                    borderLeftWidth="2px"
                    borderRightWidth="2px"
                    borderColor="neutral.900"
                    _notFirst={{ borderLeftWidth: 0 }}
                    alignItems="center"
                    gap={2}
                    p={2}
                  >
                    <Badge
                      variant="outline"
                      height="fit-content"
                      bg="gray.300"
                      rounded="full"
                      px={1.5}
                      outlineOffset={2}
                    >
                      PDF
                    </Badge>
                    <Text fontWeight={500}>{item.file_name}</Text>
                  </Flex>
                ))
            ) : (
              <Flex
                bg={bg2}
                borderTopWidth="2px"
                borderLeftWidth="2px"
                borderRightWidth="2px"
                borderColor="neutral.900"
                alignItems="center"
                gap={2}
                p={2}
              >
                <Text fontWeight={500}>{t('Please add reference data.')}</Text>
              </Flex>
            )}
          </Flex>
          {/* 메시지 창 */}
          <Box
            w="full"
            h="full"
            bg={bg2}
            borderRadius="md"
            boxShadow="lg"
            display="flex"
            flexDirection="column"
            rounded={0}
            borderWidth="2px"
            borderColor="neutral.900"
          >
            {/* 메시지 목록 (자동 스크롤 하단) */}
            <ScrollToBottom className=" flex-1-1-0 overflow-y-hidden">
              {isGuideLoading ? (
                <VStack
                  alignItems="center"
                  bg="gray.200"
                  m={4}
                  pt={2}
                  rounded="xl"
                  spacing={0}
                  w="160px"
                >
                  <Text fontSize={16} fontWeight={600}>
                    {t('Guide in Progress')}
                  </Text>
                  <LoadingThreeDots></LoadingThreeDots>
                </VStack>
              ) : guideData.summary && messages.length < 1 ? (
                <Fade in={messages.length < 1} style={{ height: '100%' }}>
                  <VStack
                    spacing="3"
                    p="4"
                    borderRadius="md"
                    boxShadow="md"
                    minHeight="full"
                    overflowY="auto"
                    rounded={0}
                  >
                    <Flex
                      w="full"
                      alignItems="center"
                      gap={1}
                      justifyContent="start"
                    >
                      <Image
                        src="/sofia-logo.svg"
                        alt="logo"
                        height="20px"
                        objectFit="contain"
                      />
                      <Text fontWeight={600}>가이드</Text>
                    </Flex>
                    <Grid
                      className="flex-1-1-0"
                      gridTemplateRows={'1fr'}
                      gridTemplateColumns={'1fr 1fr'}
                      w="full"
                    >
                      <GridItem p={4}>
                        <VStack alignItems="start" gap={4}>
                          <Text fontSize={18} fontWeight={600}>
                            {t('Data Summary')}
                          </Text>
                          <Text
                            fontSize={14}
                            fontWeight={500}
                            whiteSpace="pre-wrap"
                          >
                            {guideData.summary}
                          </Text>
                        </VStack>
                      </GridItem>
                      <GridItem p={4}>
                        <VStack alignItems="start" gap={4}>
                          <Text fontSize={18} fontWeight={600}>
                            {t('Recommended Starting Questions')}
                          </Text>
                          <VStack alignItems="start" gap={4} w="full">
                            {guideData.follow_up_questions.map(
                              (question, index) => (
                                <Box
                                  key={`guideQ-${index}`}
                                  borderWidth="2px"
                                  borderColor="neutral.900"
                                  cursor="pointer"
                                  p={3}
                                  w="full"
                                  _hover={{ bg: 'm-green.400' }}
                                  onClick={() =>
                                    handleSendMessage(question[`Q${index + 1}`])
                                  }
                                >
                                  <Text fontSize={14} fontWeight={500}>
                                    {question[`Q${index + 1}`]}
                                  </Text>
                                </Box>
                              )
                            )}
                          </VStack>
                        </VStack>
                      </GridItem>
                    </Grid>
                  </VStack>
                </Fade>
              ) : (
                <VStack
                  spacing="3"
                  p="4"
                  borderRadius="md"
                  boxShadow="md"
                  minHeight="full"
                  overflowY="auto"
                  rounded={0}
                >
                  {messages.map((msg) => (
                    <Flex
                      key={msg.id}
                      bg={msg.type === 'user' ? 'blue.100' : 'gray.200'}
                      p="3"
                      borderRadius="lg"
                      alignSelf={
                        msg.type === 'user' ? 'flex-end' : 'flex-start'
                      }
                      maxW="60%"
                      borderWidth="2px"
                      borderColor="neutral.900"
                      color="neutral.900"
                    >
                      {msg.error ? (
                        <Text whiteSpace="pre-wrap">{msg?.errorMsg}</Text>
                      ) : msg.type === 'user' ? (
                        <Text whiteSpace="pre-wrap">{msg.message}</Text>
                      ) : (
                        msg.message
                      )}
                    </Flex>
                  ))}
                  {isLoading && (
                    <Flex alignSelf="flex-start">
                      <LoadingThreeDots></LoadingThreeDots>
                    </Flex>
                  )}
                </VStack>
              )}
            </ScrollToBottom>
            <VStack
              w="full"
              bg={bg2}
              p="4"
              borderTopWidth="2px"
              borderColor="neutral.900"
            >
              {!isLoading && followUpQuestions?.length > 0 && (
                <Flex
                  gap={2}
                  flexWrap={'nowrap'}
                  overflowX="auto"
                  w="full"
                  pb={1}
                >
                  {followUpQuestions.map((question, index) => (
                    <Badge
                      key={`Q${index + 1}`}
                      variant="subtle"
                      colorScheme="m-green"
                      rounded="full"
                      fontSize={14}
                      px={2}
                      py={0.5}
                      cursor="pointer"
                      onClick={async () => {
                        await handleSendMessage(question[`Q${index + 1}`]);
                      }}
                    >
                      {question[`Q${index + 1}`]}
                    </Badge>
                  ))}
                </Flex>
              )}

              {/* 메시지 입력창 */}
              <TextareaInputBar
                input={input}
                setInput={setInput}
                onClickInput={onClickInput}
                disabled={
                  isGuideLoading ||
                  references.length < 1 ||
                  isLoading ||
                  selectedIds.length < 1
                }
              ></TextareaInputBar>
            </VStack>
          </Box>
          <Text
            align="center"
            fontSize={12}
            fontWeight={500}
            mt={2}
            color="neutral.700"
          >
            {t(
              'The chatbot may provide inaccurate responses, so it is recommended to verify each fact individually.'
            )}
          </Text>
        </GridItem>
      </Grid>
      <AddReferenceModal
        isOpen={isOpen}
        onClose={onClose}
        onUpdateReferences={updateReferences}
      ></AddReferenceModal>
    </VStack>
  );
}

export default WorkspaceChatPage;
